@import "../../scss/variables";
@import "../../scss/functions";
@import "../../scss/mixins/buttons";
@import "../../scss/mixins/direction";

$local-dots-height: 18px;
$local-dots-padding: 4px;
$local-dot-size: $local-dots-height - $local-dots-padding * 2;


.home_slide_container {
  //height: 500px !important;
  height: 540px !important;
  //border: 1px solid red;


  @media (max-width: 768px) {
    margin-top: 0;
  }

  @media (max-width: 992px) {
    height: auto !important;
  }
  @media (max-width: 1920px) {
    height: 540px !important;
  }
  @media (max-width: 1728px) {
    height: 486px !important;
  }
  @media (max-width: 1512px) {
    height: 425px !important;
  }
  @media (max-width: 1440px) {
    height: 405px !important;
  }
  @media (max-width: 1366px) {
    height: 384px !important;
  }
  @media (max-width: 1280px) {
    height: 360px !important;
  }
  @media (max-width: 1024px) {
    height: 288px !important;
  }
  @media (max-width: 991px) {
    height: auto !important;
    aspect-ratio: 1.89/1;
  }
  //@media (max-width: 834px) {
  //  height: 454px !important;
  //}
  //@media (max-width: 768px) {
  //  height: 418px !important;
  //}
  //@media (max-width: 600px) {
  //  height: 326px !important;
  //}
}

.block-slideshow__slide {
  height: 100%;
  position: relative;
  display: block;
  color: inherit;
}

.block-slideshow__slide-image {
  height: 100%;
  position: relative;
  left: 0;
  right: 0;
  width: 100%;
  background-repeat: no-repeat;
  background-position: center;
}


.block-slideshow__slide-image img {
  position: relative !important;
}

.block-slideshow__slide-image--desktop {
  img {
    /*FIXME this is temporary solution*/
    object-fit: cover;
    width: 100%;
    height: 100%;
    //border: 1px solid red;
  }

  div {
    position: relative !important;
  }
}

@media (min-width: breakpoint(lg-start)) and (max-width: breakpoint(lg-end)) {
  .block-slideshow__slide-image--desktop {
    background-position: -70px top;
  }
}

@media (min-width: breakpoint(md-start)) and (max-width: breakpoint(md-end)) {
  .block-slideshow__slide-image--desktop {
    background-position: -190px top;
  }
}

.block-slideshow__slide-image--mobile {
  > div {
    position: relative !important;
  }

  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}

.block-slideshow--layout--full {
  margin-top: 0;
  display: revert;
  height: 100%;


  .slick-dots {
    transform: translateX(-50%);
    left: 50%;
  }
}

.block-slideshow__body {
  height: 100%;
  div, span {
    height: 100% !important;
  }
}

.block-slideshow__slide .container {
  position: relative;
  flex-direction: column;
  display: flex;
  top: 50%;
  transform: translateY(-50%);
}

.block-slideshow__slide-button {
  width: 100%;
  position: absolute;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: column;
  bottom: 40px;
}

.block-slideshow__slide-button .btn-primary {
  border-radius: 22px;
}

@media screen and (max-width: 991px) {
  .block-slideshow__slide-button {
    // margin-bottom: 500px !important;
    margin-right: auto !important;
    margin-left: auto !important;
  }
}

@media (min-width: breakpoint(md-start)) {
  .block-slideshow__slide-title {
    line-height: 36px;
  }
}


@media screen and (max-width: 610px) {
  .block-slideshow__slide-title > p > span {
    font-size: 20px !important;
  }
}

///// from _block-slideshow.scss


.block-slideshow {
  position: relative;

  .slick-slide > div > div {
    vertical-align: middle;

    &:focus {
      outline: none;
    }
  }


  .slick-dots {
    width: auto;
    bottom: 13px;
    position: absolute;
    padding: $local-dots-padding;
    border-radius: $local-dots-height / 2;
    font-size: 0;
    list-style: none;
    margin: 0;

    @include direction {
      transform: translateX(-50% * $transform-direction);
      #{$inset-inline-start}: 50%;
    }

    li {
      list-style-type: none;
      display: inline-block;
    }

    button {
      width: $local-dot-size;
      height: $local-dot-size;
      padding: 0;
      border: none;
      border-radius: $local-dot-size / 2;
      background: $block-slideshow-dot-default-color;

      &:focus {
        outline: none;
      }

      &:hover {
        background: $block-slideshow-dot-hover-color;
      }
    }

    .slick-active button {
      background: $block-slideshow-dot-active-color;
    }

    li + li {
      margin-left: 6px;
    }
  }
}

.block-slideshow__slide {
  height: 100%;
  position: relative;
  display: block;
  color: inherit;
  vertical-align: middle;

  &:hover {
    color: inherit;
  }
}


.block-slideshow__slide-content {
  width: 100%;
  position: absolute;
  top: 20%;
}

.block-slideshow__slide-title {
  font-size: 30px;
  line-height: 34px;
  font-weight: $font-weight-bold;
  margin-bottom: 12px;
  transition: all 1s 0.2s;

  h3{
    text-align: center;
  }
}

.block-slideshow__slide-text {
  line-height: 1.625;
  transform: translateY(15px);
  transition: all 0.8s 0.5s;
}

.block-slideshow__slide-button {
  margin-right: auto;
  margin-left: auto;
  transition: all 1s 0.4s;
}

.block-slideshow .correct-slick-active {
  .block-slideshow__slide-title,
  .block-slideshow__slide-text,
  .block-slideshow__slide-button {
    opacity: 1;
    transform: none;
  }
}

.slick-initialized {
  height: 100%;

  .slick-list {
    height: 100%;

    .slick-track {
      height: 100%;
    }
  }
}

.slick-slide {
  & > div {
    height: 100%;
  }
}

.slideshow-btn {
  height: max-content;
  width: max-content;
  font-size: 18px;
  max-width: 200px;
}


.block-slideshow--layout--with-departments {
  $local-margin: 15px;

  @media (max-width: breakpoint(md-end)) {
    .block-slideshow__slide-button .btn {
      @include btn-sm();
    }
  }
}

@media (max-width: breakpoint(sm-end)) {
  .block-slideshow__slide-image--mobile {
    background-position: top center;
    display: block;
    height: 100%;
  }
  .block-slideshow__slide-image--desktop {
    display: none;
  }
  .block-slideshow__slide-content {
    top: 30px;
  }
  .block-slideshow__slide-title {
    font-size: 22px;
    line-height: 32px;
  }
  .block-slideshow__slide-text {
    display: none;
  }
}


@media(max-width: 650px) {

  .block-slideshow__slide-title {
    font-size: 20px;
  }
}

@media(max-width: breakpoint(xs-end)) {

  .block-slideshow__slide-title {
    font-size: 16px;
  }
}

@media(max-width: breakpoint(xs-midd)) {
  .block-slideshow__slide-title {
    font-size: 13px;
  }
}


