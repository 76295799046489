.welcome-title {
  text-align: center;
  //TODO!!! I think we dont need this
  //min-height: 85px;
  margin: 80px auto !important;

  p {
    width: 80%;
    margin: 0 auto;
  }

  h1 {
    font-size: 24px;
  }
}
